import React from 'react';

const SvgPijlRechts = ({width = '32px', fill = '#111b28', classes = null}) => {
    return (
        <svg className={classes} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 264 264" style={{ enableBackground: 'new 0 0 264 264', width: width, fill: fill }}>
            <polygon style={{ fillRule: 'evenodd', clipRule: 'evenodd'}}
                     points="11.3,147.8 191.9,147.8 128.3,211 173.2,211 252.7,132 173.2,53 128.3,53 191.9,116.2 11.3,116.2 "/>
        </svg>
    );
}
export default SvgPijlRechts;