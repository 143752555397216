'use client'
import React, {useEffect, useState, useRef} from 'react'
import style from './mkg-oplssingen.module.scss'
import SvgPijlRechts from "components/Svg/SvgPijlRechts"
import Link from "next/link";
import {useTekstClient} from "hooks/useTekstClient";
import Image from 'next/image';
import ScrollIntoView from "components/ScrollIntoView/ScrollIntoView";
import {RightMouseClickMenu} from "components/RightMouseClickMenu/RightMouseClickMenu";
import useGebruiker from "hooks/useGebruiker";
import {useAxios} from "hooks/useAxios";

const MkgOplossingen = () => {
    const tekst = useTekstClient();
    const [enableAnimation, setEnableAnimation] = useState(false);
    const [items, setItems] = useState([]);
    const mkgoplossingen = useRef()
    const weergaveId = 17
    const {ingelogdeGebruiker} = useGebruiker()
    const {get} = useAxios()


    useEffect(() => {
        get(`/weergave/${weergaveId}`)
            .then((res) => {
                setItems(res.data)
            })
    }, [])

    useEffect(() => {

        const options = {
            root: null, // The viewport is the default root
            rootMargin: '0px',
            threshold: 0.4, // Trigger when 50% of the element is visible
        };

        const callback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setEnableAnimation(true)
                } else {
                    setEnableAnimation(false)
                }
            });
        };
        const observer = new IntersectionObserver(callback, options);

        if (mkgoplossingen.current) {
            observer.observe(mkgoplossingen.current);
        }

        return () => {
            if (mkgoplossingen.current) {
                observer.unobserve(mkgoplossingen.current);
            }
        };

    }, [])



    return <>
        <div className={style.componentWrapper} ref={mkgoplossingen}><ScrollIntoView anchorNaam='#oplossingen' />
            <h1 className={style.componentHeader}>{tekst('website:mkgoplossingen:oplossingen', false , false ,true)}</h1>
            <div className={style.componentSpacer}/>
            <div className={style.oplossingenContainer}>
                {items.map((x,index,array) => {
                    return <Link key={`oplossing_${x.id}`} href={`/${x.content_gepubliceerd.intro}`} className={'relative'} style={{minWidth: "100%"}}>
                        <div className={`${style.oplossingenItem} ${enableAnimation ? style.oplossingenItemMove : ''}`}>

                            <div className={style.itemIcon}>
                                <Image
                                    src={`${process.env.NEXT_PUBLIC_BACKEND}/api/wiki/${x.voorblad_wiki_id}/NL/media`}
                                    alt={'icon'}
                                    width={88} height={88} />
                            </div>

                            <div className={style.contentContainer}>
                                <div className={style.contentText}>
                                    <h2>{x.content_gepubliceerd.titel}</h2>
                                    <div dangerouslySetInnerHTML={{__html: x.content_gepubliceerd.bericht}}/>
                                </div>
                                <div className={style.arrow}>
                                    <SvgPijlRechts fill={'#23c1f4'}/>
                                </div>
                            </div>
                        </div>

                        {ingelogdeGebruiker?.groep?.mkger && [3, 7].includes(ingelogdeGebruiker?.groep?.id) && <RightMouseClickMenu
                            id={x.id}
                            weergaveId={weergaveId}
                            bewerkMenuCurrent={x.pivot?.volgorde}
                            bewerkMenuPrev={(index - 1) in array && array[index - 1].pivot?.volgorde}
                            bewerkMenuNext={(index + 1) in array && array[index + 1].pivot?.volgorde}
                        />}
                    </Link>
                })}
                <div className={`${style.arrowGrow} ${enableAnimation ? style.arrowMove : ''}`}/>
                <div className={style.arrowTekst} dangerouslySetInnerHTML={{
                    __html: tekst('website:mkgoplossingen:automatisering', false, false, true)}}/>
            </div>
        </div>
    </>
}
export default MkgOplossingen