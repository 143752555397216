'use client'
import React, {useEffect, useRef, useState} from "react";
import classes from "./klantenkaart-mkghome.module.scss"
import {Button} from "react-bootstrap"
import {useParams, useRouter} from 'next/navigation'
import Link from 'next/link'
import {useTekstClient} from "hooks/useTekstClient";

export const KlantenkaartHome = () => {
    const router = useRouter()
    const tekst = useTekstClient()
    const [kmAfstand, setKmAfstand] = useState(30);
    const [plaats, setPlaats] = useState("")

    const elem = useRef()
    const handleKmDistance = (event) => {
        setKmAfstand(event.target.value)
    }
    const handleInput = (event) => {
        setPlaats(event.target.value);
    }
    const handleZoekButton = (km, plaats2) => {
        router.push(`/over-mkg?km=${km}&plaats=${plaats2}#klantenkaart`)
    }



    return (
        <div className={classes.imgContentContainer} ref={elem}>
            <div className={classes.headerContentIC}>
                <div className={classes.titelContentIC}>
                        <h1 className={classes.headerLandmap}>{tekst('pagina:home:klantenkaart:titel')}</h1>
                    <div style={{height: "4px", width: "64px", backgroundColor: "#fff", margin: "24px 0px"}}>&nbsp;</div>
                    <p className={classes.headerOnderschrift}>{tekst('pagina:home:klantenkaart:subtitel')}
                    </p>
                </div>
                <div className={classes.itemsContentIC}>
                    <div className={classes.itemsContentICItem}>
                        <h2 style={{color: "#111b28", margin: "0px"}}>{tekst('pagina:home:klantenkaart:teller1Getal')}</h2>
                        <div style={{
                            height: "4px",
                            width: "64px",
                            backgroundColor: "#fff",
                            margin: "12px 0px"
                        }}>&nbsp;</div>
                        <div style={{width: "160px"}}>
                            <p style={{color: "#111b28"}}>{tekst('pagina:home:klantenkaart:teller1Tekst')}</p>
                        </div>
                    </div>
                    <div className={classes.itemsContentICItem}>
                        <h2 style={{color: "#111b28", margin: "0px"}}>{tekst('pagina:home:klantenkaart:teller2Getal')}</h2>
                        <div style={{
                            height: "4px",
                            width: "64px",
                            backgroundColor: "#fff",
                            margin: "12px 0px"
                        }}>&nbsp;</div>
                        <div style={{width: "160px"}}>
                            <p style={{color: "#111b28"}}>{tekst('pagina:home:klantenkaart:teller2Tekst')}</p>
                        </div>
                    </div>
                    <div className={classes.itemsContentICItem}>
                        <h2 style={{color: "#111b28", margin: "0px"}}>{tekst('pagina:home:klantenkaart:teller3Getal')}</h2>
                        <div style={{
                            height: "4px",
                            width: "64px",
                            backgroundColor: "#fff",
                            margin: "12px 0px"
                        }}>&nbsp;</div>
                        <div style={{width: "160px"}}>
                            <p style={{color: "#111b28"}}>{tekst('pagina:home:klantenkaart:teller3Tekst')}</p>
                        </div>
                    </div>
                </div>
                <div className={classes.InputContentIC}>
                    <p>{tekst('pagina:home:klantenlijst:titel')}</p>
                    <div className={classes.inputContentItem}>
                        <input type="text" placeholder="Plaats" onChange={(event) => handleInput(event)}
                            style={{height: "42px", width: "150px", paddingLeft: "15px"}}
                        />
                        <select defaultValue={"30"} style={{height: "42px", width: "120px", padding: "0px 10px", borderRight: "10px solid transparent"}}
                            onChange={(event) => handleKmDistance(event)}
                        >
                            <option value={"30"}>&lt; 30 Km</option>
                            <option value={"50"}>&lt; 50 Km</option>
                            <option value={"100"}>&lt; 100 Km</option>
                        </select>
                        <Button size="lg" onClick={() => handleZoekButton(kmAfstand, plaats)}>
                            Zoek
                        </Button>
                    </div>
                    <div className={classes.linktozin}>
                    <p><Link href={"/over-mkg"} className={classes.linktohover}>{tekst('pagina:home:klantenkaart:linktekst')}</Link></p>
                    </div>
                </div>
            </div>
            <div className={classes.headerContentImgCont}>
                <img src={`${process.env.NEXT_PUBLIC_BACKEND}/api/wiki/9707/NL/media`} width="100%" height="auto"/>
            </div>
        </div>

    )
}
export default KlantenkaartHome